import Pagetemplate from "../Pagetemplate"
import americanflag from "../theme/images/usaflag.webp"
import brazilflag from "../theme/images/brazilflag.png"
import cvenglish from "../theme/leopCV-en.pdf"
import cvptbr from "../theme/leopCV-ptbr.pdf"

function Cv() {

  return (
    <div className="Cv">
          
      <Pagetemplate content={
        <main>
          <section>
         <div className="description">
        <h1 style={{fontSize:"3rem"}}>Download my C.V</h1>
        </div>

        <div  className="iconscv">
        
        <a className="downloadbutton" href={cvenglish} download="leopCV-en"  >
          
          <img alt="americanflag" src={americanflag}/>
          <h3>CV download</h3>
          </a>

        <a className="downloadbutton" href={cvptbr} download="leopCV-ptbr">
          <img alt="brazilianflag" src={brazilflag}/>
          <h3>CV download</h3>
          </a>



        </div>
        </section>


        </main>
   }/>




        
        
    </div>
  );
}

export default Cv;
