import { createGlobalStyle } from "styled-components";

export const TemplateGlobal = createGlobalStyle`

*{
    color: white;
}

body {
  background-color: #272727;
  margin: auto; /* Remove auto centering */
  
  font-family: sans-serif;
  max-width: 65em;
  box-sizing: border-box; /* Ensure padding and borders are included in the width */
}


    body {
  overflow-y: scroll; /* Show scrollbars */
}
    a,
    .link{
        text-decoration: none;
    }

    

    h1,
    h2,h3{
        margin: 0.5em 0;
    }

    .description{
        margin: 2em 0;
    }

    p{
        color: #D6D6D6;
        line-height: 2em;
        
    }

    section{
        margin: 5em 0;
    }

    .header,
     .body{
        border-bottom:0.5px solid #4D4D4D;
    }

    .items{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
    
    .item,
    .item > *{
        margin-left:0;
            
    }

    .item img{
        width: 17rem;
        height: 10rem;
    }

    .row,
    footer p{
        justify-content: center;
        display: flex;
    }

    .item{
        width: 17em;
        
    }

    footer{
        margin-top: 10rem;
        
    }

    .icons{
        
        width: 100%;
        /* border: 1px solid red; */
        text-align: center;
        /* padding-right:10px; */
    }

    .icons img{
        /* border: 1px solid red; */
        margin: 10px 20px;
        width: 25px;
    }
    
    .devto{
        width: 40px !important;
    }


    //cv
   
    //container
    .iconscv{
        display: flex; /* Change display to flex */
        justify-content: flex-start; /* Align items at the start */
    }

    .downloadbutton{
        padding: 15px 20px; 
        margin-left: 0;
        display: flex;
        align-items: center;
        margin-right:5rem;
        background-color: #323443;
        border: none;
        border-radius:10px;
    }
    .downloadbutton *{
        background-color: #323443;
    }


    .downloadbutton img{
        width: 50px;
        padding-right: 20px;
        background-color: #323443;
    }

    .Projects h1 {
  margin-left: 0; /* Ensure the h1 stays at the left */
}

.Projects .buttons {
  margin-left: auto; /* Ensure the buttons stay at the end of the row */
  display: flex;
  justify-content: flex-end;
}
    
`;
