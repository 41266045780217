import Pagetemplate from "../../Pagetemplate";
import GithubIcon from "../../theme/images/githubsvg.svg";
import WebsiteIcon from "../../theme/images/vector2.svg";

function Projects() {
  return (
    <div className="Projects">
      <Pagetemplate
        content={
          <main>
            <section>
              {/* Flex container to align the title and buttons in one row */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                {/* Title aligned to the left */}
                <h1 style={{ fontSize: "2.5rem" }}>Holy Bible</h1>

                {/* Buttons aligned to the far right */}
                <div
                  style={{
                    display: "flex",
                    gap: "1rem", // Adds space between the two buttons
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: "auto", // Pushes the buttons to the end
                  }}
                  className="buttons"
                >
                  {/* leo github */}
                  <div className="github">
                    <a
                      style={{
                        display: "flex",
                        flexDirection: "row", // Stacks image and text vertically
                        alignItems: "center",
                        padding: "10px 25px",
                        backgroundColor: "#323443",
                        borderRadius: "10px",
                        fontSize: "15px",
                      }}
                      href="https://github.com/LeoPivatto/bible-app"
                    >
                      <img
                        alt="githubicon"
                        src={GithubIcon}
                        style={{ width: "15px", paddingRight: "15px" }}
                      />
                      Repo
                    </a>
                  </div>

                  {/* leo website */}
                  <div className="website">
                    <a
                      style={{
                        display: "flex",
                        flexDirection: "row", // Stacks image and text vertically
                        alignItems: "center",
                        padding: "10px 25px",
                        backgroundColor: "#323443",
                        borderRadius: "10px",
                        fontSize: "15px",
                      }}
                      href="https://holybiblewebsite.netlify.app"
                    >
                      <img
                        alt="websiteicon"
                        src={WebsiteIcon}
                        style={{ width: "12px", paddingRight: "15px" }}
                      />
                      Website
                    </a>
                  </div>
                </div>
              </div>

              <p>
                I'm Leo, a passionate programmer dedicated to crafting elegant
                solutions. I specialize in Fullstack development. I'm proficient
                in Javascript, Nodejs, React, Mongodb, Git. I'm deeply
                passionate about Web Development and programming in general, and
                I'm always eager to learn and stay updated with the latest
                advancements. This portfolio gathers a collection of my side
                projects and articles.
              </p>
            </section>
          </main>
        }
      />
    </div>
  );
}

export default Projects;
