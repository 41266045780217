import Pagetemplate from "../Pagetemplate";
import img from "../theme/images/image.jpeg";
import bibleapp from "../theme/images/bibleapp.png";

function Home() {
  return (
    <div className="Home">
      <Pagetemplate
        content={
          <main>
            <section>
              <h1
                style={{
                  color: "#3BF686",
                  fontSize: "3rem",
                  textAlign: "center",
                }}
              >
                Site em construção 🚧👷🏻❤️
              </h1>
            </section>
          </main>
        }
      />
    </div>
  );
}

export default Home;
