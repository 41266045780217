import { Routes, Route } from "react-router-dom";

import Blog from "./pages/blog";
import Cv from "./pages/cv";
import Home from "./pages/home";
import Projects from "./pages/projects";
import HolyBible from "./pages/projects/holybible";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/projects/holybible" element={<HolyBible />} />
        <Route path="/cv" element={<Cv />} />
        <Route path="/projects" element={<Projects />} />
      </Routes>
    </div>
  );
}

export default App;
